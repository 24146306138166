import { gql } from 'graphql-request'

export const getRecipientPortabilityDeclaration = gql`
    query getRecipientPortability($card_number: String!) {
        applications {
            declarations {
                recipientPortability(card_number: $card_number) {
                    pdf_report
                }
            }
        }
    }
`

export const getCooperatedDeclaration = gql`
    query getCooperatedDeclaration($contract: String!) {
        applications {
            declarations {
                cooperated(contract: $contract) {
                    pdf_report
                }
            }
        }
    }  
`

export const getCooperatedBankDeclaration = gql`
    query getCooperatedBank($contract: String!) {
        applications {
            declarations {
                cooperatedBank(contract: $contract) {
                    pdf_report
                }
            }
        }
    }
`

export const getCooperatedJubilatedDeclaration = gql`
    query getCooperatedJubilated($contract: String!) {
        applications {
            declarations {
                cooperatedJubilated(contract: $contract) {
                    pdf_report
                }
            }
        }
    }
`

export const getCooperatedPublicExamDeclaration = gql`
    query getCooperatedPublicExam($contract: String!) {
        applications {
            declarations {
                cooperatedPublicExam(contract: $contract) {
                    pdf_report
                }
            }
        }
    }
`

export const getCooperatedRetiredDeclaration = gql`
    query getCooperatedRetired($contract: String!) {
        applications {
            declarations {
                cooperatedRetired(contract: $contract) {
                    pdf_report
                }
            }
        }
    }
`

export const getCooperatedRelationshipDeclaration = gql`
    query getCooperatedRelationship($contract: String!) {
        applications {
            declarations {
                cooperatedRelationship(contract: $contract) {
                    pdf_report
                }
            }
        }
    }
`
