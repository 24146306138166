import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getCooperatedDeclaration({ payload }) {
    try {
        yield put({ type: constants.DECLARATION_LOADING, payload: true })
        const res = yield call(api.getCooperatedDeclaration, payload)

        const { applications: { declarations }} = res
        const response = { pdf_report: declarations?.cooperated?.pdf_report ?? '' }

        yield put({
            type: constants.DECLARATION_DATA,
            payload: response,
        })

        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.DECLARATION_ERROR, payload: e.message })
        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    }
}

function* getCooperatedBankDeclaration({ payload }) {
    try {
        yield put({ type: constants.DECLARATION_LOADING, payload: true })

        const res = yield call(api.getCooperatedBankDeclaration, payload)

        const { applications: { declarations }} = res
        const response = { pdf_report: declarations?.cooperatedBank?.pdf_report ?? '' }

        yield put({
            type: constants.DECLARATION_DATA,
            payload: response,
        })

        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.DECLARATION_ERROR, payload: e.message })
        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    }
}

function* getCooperatedRelationshipDeclaration({ payload }) {
    try {
        yield put({ type: constants.DECLARATION_LOADING, payload: true })
        const res = yield call(api.getCooperatedRelationshipDeclaration, payload)

        const { applications: { declarations }} = res
        const response = { pdf_report: declarations?.cooperatedRelationship?.pdf_report ?? '' }

        yield put({
            type: constants.DECLARATION_DATA,
            payload: response,
        })

        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.DECLARATION_ERROR, payload: e.message })
        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    }
}

function* getCooperatedJubilatedDeclaration({ payload }) {
    try {
        yield put({ type: constants.DECLARATION_LOADING, payload: true })
        const res = yield call(api.getCooperatedJubilatedDeclaration, payload)

        const { applications: { declarations }} = res
        const response = { pdf_report: declarations?.cooperatedJubilated?.pdf_report ?? '' }

        yield put({
            type: constants.DECLARATION_DATA,
            payload: response,
        })

        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.DECLARATION_ERROR, payload: e.message })
        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    }
}

function* getCooperatedPublicExamDeclaration({ payload }) {
    try {
        yield put({ type: constants.DECLARATION_LOADING, payload: true })
        const res = yield call(api.getCooperatedPublicExamDeclaration, payload)

        const { applications: { declarations }} = res
        const response = { pdf_report: declarations?.cooperatedPublicExam?.pdf_report ?? '' }

        yield put({
            type: constants.DECLARATION_DATA,
            payload: response,
        })

        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.DECLARATION_ERROR, payload: e.message })
        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    }
}

function* getCooperatedRetiredDeclaration({ payload }) {
    try {
        yield put({ type: constants.DECLARATION_LOADING, payload: true })
        const res = yield call(api.getCooperatedRetiredDeclaration, payload)

        const { applications: { declarations }} = res
        const response = { pdf_report: declarations?.cooperatedRetired?.pdf_report ?? '' }

        yield put({
            type: constants.DECLARATION_DATA,
            payload: response,
        })

        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.DECLARATION_ERROR, payload: e.message })
        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    }
}

function* getRecipientPortabilityDeclaration({ payload }) {
    try {
        yield put({ type: constants.DECLARATION_LOADING, payload: true })
        const res = yield call(api.getRecipientPortabilityDeclaration, payload)

        const { applications: { declarations }} = res
        const response = { pdf_report: declarations?.recipientPortability?.pdf_report ?? '' }

        yield put({
            type: constants.DECLARATION_DATA,
            payload: response,
        })

        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.DECLARATION_ERROR, payload: e.message })
        yield put({ type: constants.DECLARATION_LOADING, payload: false })
    }
}

export default function* saga() {
    yield takeLatest(constants.GET_DECLARATION_COOPERATED, getCooperatedDeclaration)
    yield takeLatest(constants.GET_DECLARATION_COOPERATED_BANK, getCooperatedBankDeclaration)
    yield takeLatest(constants.GET_DECLARATION_COOPERATED_RELATIONSHIP, getCooperatedRelationshipDeclaration)
    yield takeLatest(constants.GET_DECLARATION_COOPERATED_JUBILATED, getCooperatedJubilatedDeclaration)
    yield takeLatest(constants.GET_DECLARATION_COOPERATED_PUBLIC_EXAM, getCooperatedPublicExamDeclaration)
    yield takeLatest(constants.GET_DECLARATION_COOPERATED_RETIRED, getCooperatedRetiredDeclaration)
    yield takeLatest(constants.GET_DECLARATION_RECIPIENT_PORTABILITY, getRecipientPortabilityDeclaration)
}