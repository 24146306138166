import {
    GET_DECLARATION_COOPERATED,
    GET_DECLARATION_COOPERATED_BANK,
    GET_DECLARATION_COOPERATED_RELATIONSHIP,
    GET_DECLARATION_COOPERATED_JUBILATED,
    GET_DECLARATION_COOPERATED_PUBLIC_EXAM,
    GET_DECLARATION_COOPERATED_RETIRED,
    GET_DECLARATION_RECIPIENT_PORTABILITY,    
    DECLARATION_DATA,
    DECLARATION_ERROR,
    DECLARATION_LOADING,
    CLEAR_DECLARATION_DATA,
} from 'store/constants'

export const initialState = {
    pdf_report: '',
    error: false,
    loading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DECLARATION_COOPERATED:
            return { ...initialState }
        case GET_DECLARATION_COOPERATED_BANK:
            return { ...initialState }
        case GET_DECLARATION_COOPERATED_RELATIONSHIP:
            return { ...initialState }
        case GET_DECLARATION_COOPERATED_JUBILATED:
            return { ...initialState }
        case GET_DECLARATION_COOPERATED_PUBLIC_EXAM:
            return { ...initialState }
        case GET_DECLARATION_COOPERATED_RETIRED:
            return { ...initialState }
        case GET_DECLARATION_RECIPIENT_PORTABILITY:
            return { ...initialState }
        case DECLARATION_DATA:
            const pdf_report = action?.payload?.pdf_report
            return { ...state, error: false, pdf_report }
        case DECLARATION_LOADING:
            return { ...state, loading: action.payload }
        case DECLARATION_ERROR:
            return { ...state, pdf_report: '', loading: false, error: action.payload }
        case CLEAR_DECLARATION_DATA:
            return { ...initialState }
        default:
            return state
    }
}