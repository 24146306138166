import apiClient from 'api/config/apiClient'

export default {
    getCooperatedDeclaration(payload) {
        return apiClient.request('cache-disable/declaration_cooperated', payload)
    },

    getCooperatedBankDeclaration(payload) {
        return apiClient.request('cache-disable/declaration_cooperated_bank', payload)
    },

    getCooperatedRelationshipDeclaration(payload) {
        return apiClient.request('cache-disable/declaration_cooperated_relationship', payload)
    },

    getCooperatedJubilatedDeclaration(payload) {
        return apiClient.request('cache-disable/declaration_cooperated_jubilated', payload)
    },

    getCooperatedPublicExamDeclaration(payload) {
        return apiClient.request('cache-disable/declaration_cooperated_public_exam', payload)
    },

    getCooperatedRetiredDeclaration(payload) {
        return apiClient.request('cache-disable/declaration_cooperated_retired', payload)
    },

    getRecipientPortabilityDeclaration(payload) {
        return apiClient.request('cache-disable/declaration_recipient_portability', payload)
    },
}
